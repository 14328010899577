@import url('https://fonts.googleapis.com/css2?family=Creepster&display=swap');

body {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    background-color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Creepster', system-ui;
    font-weight: 400;
    font-style: normal;
    margin-top: 0;
    margin-bottom: 0.5rem;
}

#app {
    padding: 1rem;
    max-width: 600px;
    margin: 0 auto;
}

#intro {
    text-align: center;
    margin: 50px;
}

#intro h1 {
    font-size: clamp(3rem, 10vw, 3rem);
}

#intro p {
    font-size: 0.8rem;
    font-style: italic;
    margin-bottom: 30px;
    opacity: 0.4;
}

#logo {
    filter: brightness(0) invert(1);
    width: 100%;
    max-width: 100px;
}

.room {
    color: #bada55;
    font-weight: bold;
}
p {
    margin: 0;
    padding: 0;
}

hr {
    margin-top: 30px;
    margin-bottom: 30px;
}

.announce {
    border: solid 1px #fff;
    padding: 5px 15px;
    border-radius: 5px;
    opacity: 0.3;
    margin-bottom: 10px;
}

.message {
    border: solid 1px #fff;
    padding: 5px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
}

input {
    padding: 5px;
    width: 100%;
    background-color: transparent;
    border: dashed 1px #fff;
    color: #fff;
    border-radius: 5px;
}

#chatbox {
    display: flex;
}

#chatbox .hint {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.5rem;
    color: #666;
    border: #666 1px solid;
    background-color: #eee;
    padding: 5px 5px;
    border-radius: 5px;
    margin-left: 5px;
    opacity: 0.5;
    width: 50px;
}
